import { FC } from 'react';

import { Text } from 'styles/typography';
import * as UI from 'styles/charts/EmptyChartData';
import { useTranslation } from 'src/hooks/translation';
import Loader from 'views/components/Loader';

interface EmptyChartDataProps {
  isLoading?: boolean;
}

const EmptyChartData: FC<EmptyChartDataProps> = ({ isLoading }) => {
  const t = useTranslation();

  return (
    <UI.Wrapper>
      <UI.TextWrapper>
        {isLoading ? (
          <Loader size={24} />
        ) : (
          <Text size="lg" $color="#6D6E6F">
            {t.common.noData}
          </Text>
        )}
      </UI.TextWrapper>
      <UI.Divider />
      <UI.Divider />
      <UI.Divider />
      <UI.Divider />
    </UI.Wrapper>
  );
};

export default EmptyChartData;
