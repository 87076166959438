/* eslint-disable react-hooks/exhaustive-deps */
import type { NextPage } from 'next';
import { useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';

import { useTranslation } from 'src/hooks/translation';
import { useEventStore, useQueryStore } from 'store/charts';
import { useToast } from 'views/components/Toast';
import { useFormatChart } from 'src/hooks/charts';
import * as UI from 'styles/charts';
import { EmptyChartData } from 'views/features/Charts';

const Chart = dynamic(() => import('views/features/Charts/Chart'), {
  loading: () => <EmptyChartData isLoading />,
});

const Home: NextPage = () => {
  const t = useTranslation();
  const { data: events, error: eventsError } = useEventStore();
  const { data: queries, error: queriesError } = useQueryStore();

  const isEventsLoading = useMemo(() => !events && !eventsError, [events, eventsError]);
  const isQueriesLoading = useMemo(() => !queries && !queriesError, [queries, queriesError]);

  const { showError } = useToast();

  const eventsData = useFormatChart(events?.data?.result);
  const queriesData = useFormatChart(queries?.data?.result);

  useEffect(() => {
    if (eventsError?.error) {
      showError(eventsError);
    }
  }, [eventsError]);

  useEffect(() => {
    if (queriesError?.error) {
      showError(queriesError);
    }
  }, [queriesError]);

  return (
    <>
      <UI.Wrapper>
        <UI.Title className="bold" size="xl" $color="#000000">
          {t.dashboard.chart.titleEvent}
        </UI.Title>

        {Array.isArray(eventsData) && eventsData?.length > 0 ? (
          <Chart data={eventsData} type="event" />
        ) : (
          <EmptyChartData isLoading={isEventsLoading} />
        )}
      </UI.Wrapper>

      <UI.Wrapper>
        <UI.Title className="bold" size="xl" $color="#000000">
          {t.dashboard.chart.titleQuery}
        </UI.Title>

        {Array.isArray(queriesData) && queriesData?.length > 0 ? (
          <Chart data={queriesData} type="query" />
        ) : (
          <EmptyChartData isLoading={isQueriesLoading} />
        )}
      </UI.Wrapper>
    </>
  );
};

export default Home;
