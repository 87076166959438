import { useMemo } from 'react';

const useFormatChart = (data?: ResultChart[]): { date: number; value: number }[] | undefined =>
  useMemo(
    () =>
      data
        ?.map(({ values }) => values)
        .flat(1)
        .map(([date, value]) => ({
          date,
          value: Math.round(+value),
        })),
    [data],
  );

export default useFormatChart;
