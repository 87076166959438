import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding: 9px 35px 0 53px;
  width: 100%;
  height: 300px;
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.gray[2]};
  height: 65px;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[3]};
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: calc(50% - 9px);
  left: 50%;
  padding: 42px 40px;

  background-color: #fff;

  transform: translate(-50%, -50%);

  svg {
    stroke: ${(p) => p.theme.color.gray[9]};
  }

  z-index: 99;
`;

export { Wrapper, Divider, TextWrapper };
