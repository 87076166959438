import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FC, useMemo } from 'react';
import { DateTime } from 'luxon';

import { Text } from 'styles/typography';
import { useTimezone } from 'src/hooks/date';

interface IChart {
  data?: { date: number; value: number }[];
  type: 'event' | 'query';
  withAdditionalTooltipDate?: boolean;
}

const Chart: FC<IChart> = ({ data, type, withAdditionalTooltipDate = false }) => {
  const isEvent = type === 'event';

  const { isLocal } = useTimezone();

  const isNoValue = useMemo(() => (data ? !data.find(({ value }) => value > 0) : false), [data]);

  const convertTime = (date: number) =>
    isLocal
      ? DateTime.fromMillis(date * 1000).toFormat('HH:mm')
      : DateTime.fromMillis(date * 1000)
          .toUTC()
          .toFormat('HH:mm');

  const convertDate = (date: number) =>
    DateTime.fromMillis(date).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id={isEvent ? 'colorUv' : 'colorPv'} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={isEvent ? '#8884d8' : '#82ca9d'} stopOpacity={0.1} />
              <stop offset="85%" stopColor={isEvent ? '#8884d8' : '#82ca9d'} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tick={{ fontSize: '12px', fill: 'rgba(79, 79, 79, 0.8)' }}
            stroke="rgba(79, 79, 79, 0.8)"
            domain={['dateMin', 'dateMax']}
            type="number"
            tickFormatter={convertTime}
            tickCount={125}
          />
          <YAxis
            tick={{ fontSize: '12px', fill: 'rgba(79, 79, 79, 0.8)' }}
            stroke="rgba(79, 79, 79, 0.4)"
            type="number"
            domain={
              isNoValue
                ? [(dataMin: number) => 0 - Math.abs(dataMin) - 4, (dataMax: number) => dataMax + 4]
                : undefined
            }
          />
          <Tooltip
            labelFormatter={(date) => (
              <Text as="span">
                {convertTime(date)}
                {withAdditionalTooltipDate && ` (${convertDate(date)})`}
              </Text>
            )}
          />
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Area
            dataKey="value"
            stroke={isEvent ? '#8884d8' : '#82ca9d'}
            fillOpacity={1}
            fill={isEvent ? 'url(#colorUv)' : 'url(#colorPv)'}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;
