import styled from 'styled-components';

import { Text } from 'styles/typography';

const Wrapper = styled.div`
  margin-bottom: 58px;
  width: 100%;

  :last-child {
    margin-bottom: 0;
  }
`;

const Title = styled(Text)`
  margin-bottom: 15px;
`;

export { Wrapper, Title };
