import { getUserCookies } from 'utils/cookies';

const useTimezone = () => {
  const { timezone } = getUserCookies();

  const isLocal = timezone && timezone === 'local';

  return { timezone, isLocal };
};

export default useTimezone;
