import useSWR from 'swr';
import { DateTime } from 'luxon';
import axios from 'axios';

const useEventStore = <K>(options?: K) => {
  const date = DateTime.now().setZone('utc');

  const start = (date.minus({ hours: 12 }).toMillis() / 1000).toString();
  const end = (date.toMillis() / 1000).toString();

  return useSWR<ChartsResponse, ResponseError>(
    'events-chart-store',
    () =>
      axios
        .get(`${window.location.origin}/prometheus/api/v1/query_range`, {
          params: { query: 'sum(rate(indexer_rows_written_sum[60s]))', start, end, step: '172' },
        })
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      ...(options ?? {}),
    },
  );
};

export default useEventStore;
